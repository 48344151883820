<template lang="pug">
div
  label(
    class="vs-select--label"
  ) {{ $t('datauser.document_type') }}
  v-select(
    :options="documentType"
    :label="`${$i18n.locale}`"
    :clearable="false"
    :placeholder='$t("datauser.document_type")'
    v-model="document_types"
  )
    template(
      slot="option"
      slot-scope="option"
    ) {{ $t('users.' + option.name) }}
</template>

<script>

import { mapState, mapGetters } from "vuex"

/**
 * Constantes para este componente
 */
const COMPONENT_NAME = 'DocumentSelect'

export default {
  name: COMPONENT_NAME,
  props: {
    activeUpdate: {
      type: Boolean,
      required: false
    },
    documentSelect: {
      required: false
    }
  },
  data () {
    return {
      document_types: null,
    }
  },
  computed: {
    nameDocument: {
      get () {
        return this.$t('users.' + this.document_types.name)
      }
    },
    ...mapGetters("auth", [
      "currentUser"
    ]),
    ...mapState([
      'documentType'
    ])
  },
  mounted () {
    if (this.activeUpdate) {
      let dateUser = this.currentUser
      this.selectUpdate(dateUser.document_type)
    }
  },
  methods: {
    clear () {
      this.document_types = null
    },
    selectUpdate (val) {
      this.documentType.forEach(( row ) => {
        if (row.id == val) {
          this.document_types = row
        }
      })
    }
  },
  watch: {
    documentSelect (val) {
      this.selectUpdate(val)
    },
    document_types () {
      this.$emit('input', this.document_types)
      this.$emit('change')
    }
  }
}
</script>
<style scoped>
.vs-select--label {
  padding-left: 5px;
  font-size: .7rem;
}
</style>
<style>
.stf-select__inner-wrapper{
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}
</style>
