<template lang="pug">
div
  label(
    class="vs-select--label"
  ) {{ $t('select.company') }}
  v-select(
    :options="companies"
    label="name"
    :clearable="false"
    :placeholder='$t("select.company")'
    v-model="company"
  )
    template(
      slot="option"
      slot-scope="option"
    ) {{ option.name | capitalize }}
</template>
<script>

import HTTP from "@/http-common"
/**
 * Constantes para este componente
 */
const COMPONENT_NAME = 'CompanySelect'
export default {
  name: COMPONENT_NAME,
  props: ['companySelect'],
  data () {
    return {
      company: null,
      companies: [],
    }
  },
  methods: {
    getCompany () {
      let url =`/v1/companies/companies/`
      HTTP.get(url).then((response) => {
        this.companies = response.data
      })
    },
    clear () {
      this.company = null
    },
    selectCompany () {
      this.companies.forEach(( row ) => {
        if (row.id == this.companySelect.id) {
          this.company = row
        }
      })
    }
  },
  mounted () {
    this.getCompany()
  },
  watch: {
    companySelect () {
      this.selectCompany()
    },
    company () {
      this.$emit('input', this.company)
      this.$emit('change')
    }
  }
}
</script>
<style>
.vs-select--label {
  padding-left: 5px;
  font-size: .7rem;
}
</style>