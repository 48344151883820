import HTTP from '@/http-common'
export default {

  getCountry() {
    let request = [
      '/countries/'
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  getUserData (id) {
    let request = [
      `v1/users/${id}`
    ]
    return new Promise((resolve, reject) => {
      HTTP.get(...request).then((response) => {
        resolve(response)
      }).catch((response) => {
        reject(response)
      })
    })
  },
  getUsersData () {
    let request = [
      `v1/users/getusers/`
    ]
    return new Promise((resolve, reject) => {
      HTTP.get(...request).then((response) => {
        resolve(response)
      }).catch((response) => {
        reject(response)
      })
    })
  },
  getRoles() {
    let request = [
      'v1/roles/roles/'
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  createUser (data) {
    let request = [
      'v1/users/register/form',
      data
    ]
    HTTP.setHeader(true)
    return new Promise((resolve, reject) => {
      HTTP.post(...request).then((response) => {
        resolve(response)
      }).catch((response) => {
        reject(response)
      })
    })
  },
  getAllUsers () {
    let request=[`${process.env.VUE_APP_URL_API}/users/all`]
    return new Promise((resolve, reject)=>{
      HTTP.setHeader(true)
      HTTP.query(...request).then(resolve).catch(reject)
    })
  },
  getUsers () {
    let request = [
      '/users/detail'
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  updateUsers(data) {
    let request = [
      `/v1/users/update/${data.id}`,
      data
    ]
    return new Promise((resolve, reject) => {
      HTTP.put(...request).then((response) => {
        resolve(response)
      }).catch((response) => {
        reject(response)
      })
    })
  },
  changeStateActivate(data) {
    let request = [
      `/v1/users/update/status/`,
      data
    ]
    HTTP.setHeader(true)
    return new Promise((resolve, reject) => {
      HTTP.post(...request).then((response) => {
        resolve(response)
      }).catch((response) => {
        reject(response)
      })
    })
  }

}