<template lang="pug">
div
  label(
    class="vs-select--label"
  ) {{ $t('users.roles') }}
  v-select(
    :options="profiles"
    label="name"
    :clearable="false"
    :placeholder='$t("users.roles")'
    v-model="profile"
  )
    template(
      slot="option"
      slot-scope="option"
    ) {{ option.name | capitalize }}
</template>

<script>

import HTTP from "@/http-common"
/**
 * Constantes para este componente
 */
const COMPONENT_NAME = 'ProfileSelect'

export default {
  name: COMPONENT_NAME,
  props:{
    activeUpdate: {
      type: Boolean,
      required: false
    },
    profileSelect:{
      required: false
    }
  },
  data () {
    return {
      profile: null,
      profiles: [],
    }
  },
  methods: {
    getRoles () {
      HTTP.get("/v1/roles/roles/").then((response) => {
        response.data.forEach((row) => {
          this.profiles.push({
            id: row.id,
            name: row.name
          })
        })
      })
    },
    clear () {
      this.profile = null
    },
    selectUpdate (val) {
      this.profiles.forEach(( row ) => {
        if (row.id == val) {
          this.profile = row
        }
      })
    }
  },
  watch: {
    profileSelect (val) {
      this.getRoles()
      if (val.length > 0) {
        this.selectUpdate(val[0].id)
      } else {
        this.selectUpdate(null)
      }
    },
    profile () {
      this.$emit('input', this.profile)
      this.$emit('change')
    }
  }
}
</script>
<style scoped>
.vs-select--label {
  padding-left: 5px;
  font-size: .7rem;
}
</style>