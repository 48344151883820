<template lang="pug">
  vue-form
</template>

<script>

import FormSp from "@/components/FormSp/Index"

// Constantes
const COMPONENT_NAME= "FormSp"

export default {
  name: COMPONENT_NAME,
  components: {
    "vue-form": FormSp
  }
}
</script>
