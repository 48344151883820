import HTTP from '@/http-common'
export default {

  getStatus(id_company) {
    let request = [
      `/v1/status/status/?company_id=${id_company}`
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  getStatusForm(id_company) {
    let request = [
      `/v1/status/status/form/?company_id=${id_company}`
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  getStatusAll() {
    let request = [
      `/v1/status/status/all`
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  statusDelete(id) {
    HTTP.setHeader(true)
    let request = [
      `/v1/status/delete/${id}`
    ]
    return new Promise((s, r) => {
      HTTP.delete(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  statusDeleteMultiple(data) {
    HTTP.setHeader(true)
    let request = [
      '/v1/status/delete/multiple/',
      data
    ]
    return new Promise((resolve, reject) => {
      HTTP.post(...request).then(resolve).catch(reject)
    })
  },
  statusUpdateMultiple(data) {
    HTTP.setHeader(true)
    let request = [
      '/v1/status/update/multiple/',
      data
    ]
    return new Promise((resolve, reject) => {
      HTTP.post(...request).then(resolve).catch(reject)
    })
  }
}